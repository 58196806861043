@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Amatic+SC:wght@400;700&family=Poppins:ital,wght@0,200;0,400;0,600;1,200&family=Source+Sans+Pro:wght@300;400&family=Ubuntu+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Handlee&display=swap");
#mainh1 {
  text-align: center;
  margin: 15px 0px;
  font-size: 2.8em;
}

.cCard, .orderCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  position: relative;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.199);
  font-family: "Source Sans Pro", sans-serif;
  text-align: center;
  transform: rotate(0);
}
.cCard img, .orderCard img {
  padding: 10px;
  border-radius: 20px;
  width: 95%;
}
.cCard a, .orderCard a {
  margin-bottom: 5px;
}
.cCard h2, .orderCard h2 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.2em;
  cursor: default;
}
.cCard input, .orderCard input {
  opacity: 0;
}
.cCard .btnInfo, .orderCard .btnInfo {
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  margin: 5px;
  transition: opacity 0.3s;
}

.cCard:hover .btnInfo, .orderCard:hover .btnInfo {
  opacity: 1;
}

.orderCard {
  background-color: #eed9b2;
  border: 1px solid #581c41;
  padding: 15px;
  margin: 0px 2px;
}

.buttn, #relaxSite .relaxButton {
  background-color: #e7c380;
  border: 0.5px solid black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.103);
  font-family: "Ubuntu Condensed", sans-serif;
  border-radius: 5px;
  transition: 0.3s;
  padding: 3px;
  font-size: 1.2em;
}

.buttn:disabled, #relaxSite .relaxButton:disabled {
  opacity: 50%;
}

.buttn:hover, #relaxSite .relaxButton:hover {
  background-color: #86546e;
  color: black;
}

#franjaTop {
  display: flex;
  justify-content: center;
  padding: 15px 0px;
  background-size: cover;
}
#franjaTop img {
  width: 130px;
}

#botonesIndex {
  background-color: #7c3359;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  min-height: 20vh;
  margin-top: 10px;
}
#botonesIndex h2 {
  color: white;
  margin: 0px;
}
#botonesIndex #b1 {
  background-color: #7c3359;
}
#botonesIndex #b2 {
  background-color: rgba(88, 28, 65, 0.4470588235);
}
#botonesIndex #b3 {
  background-color: #581c41;
}
#botonesIndex #b1, #botonesIndex #b2, #botonesIndex #b3 {
  width: 100%;
  height: 20vh;
  transition: 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
#botonesIndex #b1:hover, #botonesIndex #b2:hover, #botonesIndex #b3:hover {
  background-color: #88466f;
}

#offCanvas {
  background-color: #7c3359 !important;
}
#offCanvas a, #offCanvas div {
  color: white;
  font-size: large;
  text-decoration: none;
}
#offCanvas img {
  width: 1.9em;
  cursor: pointer;
}
#offCanvas .offcanvasItems a {
  margin: 10px 0px;
}

.userNavbar {
  background-color: #69224e !important;
}

.navbar {
  background-color: #7c3359;
  padding: 0px;
}
.navbar #cartLogo {
  width: 1.9em;
  cursor: pointer;
}
.navbar .navItems {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
.navbar .navItems a {
  margin: 0px 10px;
  color: white;
  font-size: large;
  text-decoration: none;
}
.navbar .navItems a .active {
  color: white;
}
.navbar button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.cell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 50%;
  margin: 0px 5px;
}
.cell img {
  width: 50%;
  margin: 0px 3px;
}

#boxCarousel .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") !important;
}
#boxCarousel .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
#boxCarousel .carousel-inner {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px 5em;
}

#containerMap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  background: rgb(240, 230, 199);
  background-color: rgb(100, 31, 74);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 15px;
}
#containerMap #cmColumn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
#containerMap #cmColumn img {
  width: 85%;
}
#containerMap #cmColumn div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
}
#containerMap #cmIframe {
  margin-top: 15px;
  width: 100%;
  text-align: center;
}
#containerMap #cmIframe iframe {
  width: 95%;
}

#filtros {
  padding: 10px;
}
#filtros h2 {
  color: white !important;
  font-family: "Ubuntu Condensed", sans-serif;
}
#filtros h3 {
  font-size: 2em;
}
#filtros ul {
  list-style: none;
  margin-top: 0;
  padding-left: 10px;
}
#filtros ul li {
  margin: 8px 0px;
  font-size: 1.1em;
}
#filtros ul input {
  margin-right: 5px;
}
#filtros button {
  margin-bottom: 15px;
}
#filtros .accordion-button:not(.collapsed), #filtros .accordion-button {
  color: black;
  background-color: #69224e;
}
#filtros .accordion-button::after {
  display: none !important;
}

.swal2-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 9999999999;
}
.swal2-popup h2 {
  font-weight: 600;
  font-size: 1.3em;
  text-align: center;
}
.swal2-popup h3 {
  font-weight: 400;
  font-size: 1.1em;
  text-align: center;
  padding: 0px 50px;
  color: black;
}
.swal2-popup .info-image {
  width: 100%;
  padding: 0px;
}
.swal2-popup img {
  padding: 15px;
  width: 85%;
}
.swal2-popup .productList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.bgText {
  background-color: #7c3359 !important;
}

.preLoader, .preLoaderCard, .preLoaderTransparent {
  background-color: #f0e6c7;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 9999;
}

.preLoaderTransparent {
  background-color: rgba(240, 230, 199, 0.937254902) !important;
}

.preLoaderCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  position: absolute;
  height: 40vh;
}

.counter {
  text-align: center;
}
.counter .counter-button {
  padding: 0px 10px;
  border: 0.5px solid black;
  background-color: #e7c380;
  color: black;
  font-size: 25px;
  cursor: pointer;
}
.counter .counter-button-left {
  border-radius: 5px 0 0 5px;
}
.counter .counter-button-right {
  border-radius: 0 5px 5px 0;
}
.counter .counter-value {
  margin: 0px 10px;
  font-size: 25px;
}

#customBoxContainer {
  margin: 15px 120px;
}
#customBoxContainer .brand {
  background-color: #7c3359;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  color: white;
  height: 5vh;
}
#customBoxContainer .brand h2 {
  margin: 0px;
  font-size: 1.5em;
}
#customBoxContainer .accGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1rem;
  text-align: center;
  padding: 15px;
  background-color: white;
}
#customBoxContainer #customBoxContainer-item1, #customBoxContainer #customBoxContainer-item2, #customBoxContainer #customBoxContainer-item3 {
  position: relative;
  margin-bottom: 20px;
  box-shadow: 1px 0px 0px rgba(88, 28, 65, 0.4470588235), 0px 1px 0px rgba(88, 28, 65, 0.4470588235), 2px 1px 0px rgba(88, 28, 65, 0.4470588235), 1px 2px 0px rgba(88, 28, 65, 0.4470588235), 3px 2px 0px rgba(88, 28, 65, 0.4470588235), 2px 3px 0px rgba(88, 28, 65, 0.4470588235), 4px 3px 0px rgba(88, 28, 65, 0.4470588235), 3px 4px 0px rgba(88, 28, 65, 0.4470588235), 5px 4px 0px rgba(88, 28, 65, 0.4470588235), 4px 5px 0px rgba(88, 28, 65, 0.4470588235), 6px 5px 0px rgba(88, 28, 65, 0.4470588235), 5px 6px 0px rgba(88, 28, 65, 0.4470588235), 7px 6px 0px rgba(88, 28, 65, 0.4470588235), 6px 7px 0px rgba(88, 28, 65, 0.4470588235), 8px 7px 0px rgba(88, 28, 65, 0.4470588235), 7px 8px 0px rgba(88, 28, 65, 0.4470588235), 9px 8px 0px rgba(88, 28, 65, 0.4470588235), 8px 9px 0px rgba(88, 28, 65, 0.4470588235), 10px 9px 0px rgba(88, 28, 65, 0.4470588235), 9px 10px 0px rgba(88, 28, 65, 0.4470588235), 11px 10px 0px rgba(88, 28, 65, 0.4470588235), 10px 11px 0px rgba(88, 28, 65, 0.4470588235), 12px 11px 0px rgba(88, 28, 65, 0.4470588235), 11px 12px 0px rgba(88, 28, 65, 0.4470588235), 13px 12px 0px rgba(88, 28, 65, 0.4470588235);
}
#customBoxContainer .selectedCard {
  background-color: #ffe9b9;
}

.delicate-form {
  max-width: 400px;
  margin: 25px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #eed9b2;
}
.delicate-form h2 {
  color: #581c41;
}
.delicate-form .form-group {
  margin-bottom: 15px;
}
.delicate-form .form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #7c3359;
}
.delicate-form .form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #7c3359;
  border-radius: 5px;
  background-color: #fff;
  color: #7c3359;
}
.delicate-form .checkbox-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}
.delicate-form .checkbox-group label {
  margin-left: 10px;
  font-weight: bold;
  color: #7c3359;
}
.delicate-form .checkbox-group input {
  width: 20px;
  height: 20px;
}
.delicate-form .form-group input::-moz-placeholder {
  color: #7c3359;
}
.delicate-form .form-group input::placeholder {
  color: #7c3359;
}

#loginReady {
  border-radius: 2px;
  background-color: #bbd66f;
  padding: 5px;
  text-align: center;
  margin-top: 10px;
}
#loginReady h3 {
  margin: auto;
}

#profileContainer, #formPedido, #cartContainer {
  background-color: #eed9b2;
  margin: 35px;
  padding: 10px;
  border: 1px solid #581c41;
  border-radius: 10px;
}
#profileContainer h3, #formPedido h3, #cartContainer h3 {
  font-size: 1.5em;
  color: #7c3359;
  margin: 0px;
}
#profileContainer h2, #formPedido h2, #cartContainer h2 {
  font-size: 2.2em;
}
#profileContainer p, #formPedido p, #cartContainer p {
  margin: 0px;
  font-size: 2em;
}

.longCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: white;
  padding: 10px;
  margin: 10px;
}
.longCard h3 {
  font-size: 1.2em !important;
}
.longCard #firstHalf {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  width: 60%;
}
.longCard #firstHalf img {
  width: 100px;
}
.longCard #secondHalf {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  width: 40%;
}
.longCard #secondHalf div {
  text-align: center;
}

.carritoVacio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin: 150px 0px 400px 0px;
}

#formPedido form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
}
#formPedido form input {
  margin: 2px 0px;
}
#formPedido #nombreCorreo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
#formPedido #radiosRetiro {
  margin: 0px 15px;
}
#formPedido #radiosRetiro div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin: 5px 0px;
}
#formPedido #envRet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
#formPedido #divBoton {
  margin: auto 0px auto auto;
}

#btnWhatsapp {
  background-color: green;
  position: fixed;
  right: 15px;
  bottom: 30%;
  border-radius: 15%;
  z-index: 999;
}
#btnWhatsapp img {
  padding: 5px;
  width: 70px;
}

#helpButton {
  z-index: 99999;
  opacity: 50%;
}

#helpButton:hover {
  opacity: 100%;
}

#btnRelax {
  background-color: #90bdbc;
  position: fixed;
  right: 15px;
  bottom: -50px;
  z-index: 999;
  border-radius: 20%;
  padding: 10px;
  transition: 0.5s;
}
#btnRelax img {
  width: 180px;
}
#btnRelax .switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 100px;
  margin: auto;
}
#btnRelax .switch input {
  display: none;
}
#btnRelax .slider {
  background-color: #f0e6c7;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}
#btnRelax .slider:before {
  background-color: #7c3359;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}
#btnRelax input:checked + .slider:before {
  transform: translateX(64px);
  background-color: #9ecfce;
}
#btnRelax input:checked + .navbar {
  background-color: #4d7978 !important;
}
#btnRelax .slider.round {
  border-radius: 34px;
}
#btnRelax .slider.round:before {
  border-radius: 50%;
}

#btnRelax:hover {
  transform: translateY(-42px);
}

#liquidTransition {
  width: 100vw; /* El 100% del ancho de la ventana */
  height: 100vh; /* El 100% de la altura de la ventana */
  position: fixed; /* Se mantiene fija en la ventana */
  top: 0; /* Está en la parte superior */
  left: 0; /* Está en el lado izquierdo */
  z-index: 99999999; /* Ajusta la superposición */
}

#relaxSite {
  margin: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
#relaxSite img {
  width: 50vw;
}
#relaxSite #relaxSiteButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #9ecfce;
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid #2e4645;
  border-radius: 10px;
}
#relaxSite #relaxSiteButtons img {
  width: 300px;
}
#relaxSite #relaxSiteButtons div {
  width: 100%;
  padding: 0px 25px;
}
#relaxSite #relaxSiteButtons div h3 {
  font-family: "Handlee", cursive;
  font-size: 2rem;
  text-align: center;
  color: white;
}
#relaxSite #relaxSiteButtons div img {
  width: 100px;
  display: block;
  margin: auto;
}
#relaxSite .relaxButton {
  background-color: #76bdbc;
  color: white;
  width: 100%;
  height: 10vh;
  font-size: 2.5em;
}
#relaxSite .relaxButton:hover {
  background-color: #91dad8;
  color: white;
}

html {
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, a, p, li {
  font-family: "Ubuntu Condensed", sans-serif;
}

p {
  font-size: 1.5rem;
}

body {
  background-color: #f0e6c7;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: black;
}

h1 {
  font-size: 2.7em;
  color: #581c41;
}

h3 {
  font-size: 1.4em;
}

footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 15px 0px;
  background: linear-gradient(0deg, rgb(69, 22, 51) 0%, rgb(105, 34, 78) 100%);
  margin-top: auto;
}
footer a {
  color: white;
  font-size: 1.2em;
}
footer p {
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.2em;
}
footer img {
  color: white;
  width: 1.3em;
  margin-right: 5px;
}
footer h3 {
  color: white;
}

hr {
  border: 0;
  clear: both;
  display: block;
  width: 96%;
  background-color: #581c41;
  height: 1px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
  color: white;
}

th {
  background-color: #7c3359;
  color: white;
}

tr:nth-child(even) {
  background-color: #86546e;
}

tr:nth-child(odd) {
  background-color: #866777;
}

.tableContainer {
  overflow-x: auto;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 767px) {
  #boxCarousel .carousel-inner {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px 1em;
  }
  #boxCarousel .cell img {
    width: 90%;
    margin-bottom: 10px;
  }
  #botonesIndex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0px;
    max-height: 5vh;
  }
  #botonesIndex a {
    margin: 2px 0px;
    min-height: 5vh;
  }
  #botonesIndex a h2 {
    align-self: center;
  }
  .longCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }
  .longCard #firstHalf {
    width: 100%;
  }
  .longCard #secondHalf {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }
  .longCard #secondHalf div {
    margin: 0px 15px;
  }
  #btnWhatsapp img {
    width: 50px;
  }
}
@media screen and (min-width: 501px) and (max-width: 950px) {
  #customBoxContainer {
    margin: 15px 40px;
  }
  #customBoxContainer .accGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-gap: 1rem;
  }
}
@media (max-width: 500px) {
  #mainh1 {
    font-size: 1.5em;
  }
  #customBoxContainer {
    margin: 15px 20px;
  }
  #customBoxContainer .accGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: 1rem;
  }
}
@media (max-width: 960px) {
  #relaxSite {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }
  #relaxSite img {
    width: 75vw;
  }
}/*# sourceMappingURL=main.css.map */