@mixin flex($dir, $just, $align, $wrap){
    display: flex;
    flex-direction: $dir;
    justify-content: $just;
    align-items: $align;
    flex-wrap: $wrap;
}

@mixin grid($cols, $rows, $area, $just, $al){
    display: grid;
    grid-template-columns: $cols;
    grid-template-rows: $rows;
    grid-template-areas: $area;
    justify-items: $just;
    align-items: $al;
}